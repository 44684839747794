import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import moment from "moment"
import axios from "axios"
import Modal from "../Modal"
import { Row, Column } from "../Grid"
import { Form, TextInput, Checkbox, Select, Button } from "../Form"
import "./styles.scss"

class Subscribe extends React.Component {
  state = {
    formFields: {
      email: {
        value: "",
      },
      birthdayDay: {
        value: "",
      },
      birthdayMonth: {
        value: "",
      },
      birthdayYear: {
        value: "",
      },
      inGradesKto3: {
        value: false,
      },
      inGrades4to5: {
        value: false,
      },
      inGrades6to8: {
        value: false,
      },
      inGrades9to12: {
        value: false,
      },
      interestedInEducation: {
        value: false,
      },
      interestedInSTEM: {
        value: false,
      },
      interestedInLanguageArts: {
        value: false,
      },
      interestedInVirtualFieldTrips: {
        value: false,
      },
      interestedInSweepstakesAndChallenges: {
        value: false,
      },
      interestedInSocialEmotionalLearning: {
        value: false,
      },
      subscribeToDE: {
        value: false,
      },
      subscribeToPartner: {
        value: false,
      },
    },
    errors: [],
    formComplete: false,
  }

  handleFormChange = (field, value) => {
    this.setState(state => ({
      formFields: {
        ...state.formFields,
        [field]: {
          value,
        },
      },
    }))
  }

  handleFormSubmit = () => {
    const { formFields } = this.state
    const validationErrors = []
    const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formFields.email.value)
    const birthday = moment(
      `${formFields.birthdayYear.value}/${formFields.birthdayMonth.value}/${formFields.birthdayDay.value}`
    )

    const age = moment().diff(birthday, "years")

    if (!emailValid) {
      validationErrors.push("Please provide a valid email address.")
    }

    if (!birthday.isValid()) {
      validationErrors.push("Please provide your date of birth.")
    }
    else if (age < 13) {
      validationErrors.push(
        "You have to be 13 years of age or older to sign up for updates."
      )
    }

    if (validationErrors.length) {
      this.setState(
        {
          errors: validationErrors,
        },
        () => {
          this.errors.scrollIntoView({ behavior: "smooth" })
        }
      )
      return
    } else {
      this.setState({
        errors: {},
      })
    }

    const fields = {
      email: formFields.email.value,
      birthday: `${formFields.birthdayMonth.value}/${formFields.birthdayDay.value}/${formFields.birthdayYear.value}`,
      inGradesKto3: formFields.inGradesKto3.value,
      inGrades4to5: formFields.inGrades4to5.value,
      inGrades6to8: formFields.inGrades6to8.value,
      inGrades9to12: formFields.inGrades9to12.value,
      interestedInEducation: formFields.interestedInEducation.value,
      interestedInSTEM: formFields.interestedInSTEM.value,
      interestedInLanguageArts: formFields.interestedInLanguageArts.value,
      interestedInVirtualFieldTrips:
        formFields.interestedInVirtualFieldTrips.value,
      interestedInSweepstakesAndChallenges:
        formFields.interestedInSweepstakesAndChallenges.value,
      interestedInSocialEmotionalLearning: formFields.interestedInSocialEmotionalLearning.value,
      subscribeToDE: formFields.subscribeToDE.value,
      subscribeToPartner: formFields.subscribeToPartner.value,
    }

    axios.post(
      `https://cepdata.discoveryeducation.com/api/v2/form`,
      {
        property_id: "600af5b0dcb9d647c776b017",
        // property_id: "5e3dcf7b37485923b5123c08",
        fields,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    this.setState({ formComplete: true })
  }

  renderFields = () => {
    const { formFields } = this.state
    /* Is 'data' being used? */
    /* const { data } = this.props */
    return (
      <Form onSubmit={this.handleFormSubmit}>
        <TextInput
          type="email"
          label="Email"
          name="email"
          placeholder="email@address.com"
          value={formFields.email.value}
          onChange={this.handleFormChange}
          required
        />
        <Row>
          <Column span={6}>
            <h3>Grade Band</h3>
            <Checkbox
              name="inGradesKto3"
              label="Grades K–3"
              checked={formFields.inGradesKto3.value}
              onChange={this.handleFormChange}
            />
            <Checkbox
              name="inGrades4to5"
              label="Grades 4–5"
              checked={formFields.inGrades4to5.value}
              onChange={this.handleFormChange}
            />
            <Checkbox
              name="inGrades6to8"
              label="Grades 6–8"
              checked={formFields.inGrades6to8.value}
              onChange={this.handleFormChange}
            />
            <Checkbox
              name="inGrades9to12"
              label="Grades 9–12"
              checked={formFields.inGrades9to12.value}
              onChange={this.handleFormChange}
            />
          </Column>
          <Column span={6}>
            <h3>Interests</h3>
            <Checkbox
              name="interestedInEducation"
              label="Health &amp; Physical Education"
              checked={formFields.interestedInEducation.value}
              onChange={this.handleFormChange}
            />
            <Checkbox
              name="interestedInSTEM"
              label="STEM"
              checked={formFields.interestedInSTEM.value}
              onChange={this.handleFormChange}
            />
            <Checkbox
              name="interestedInLanguageArts"
              label="Language Arts"
              checked={formFields.interestedInLanguageArts.value}
              onChange={this.handleFormChange}
            />
            <Checkbox
              name="interestedInVirtualFieldTrips"
              label="Virtual Field Trips"
              checked={formFields.interestedInVirtualFieldTrips.value}
              onChange={this.handleFormChange}
            />
            <Checkbox
              name="interestedInSweepstakesAndChallenges"
              label="Sweepstakes &amp; Challenges"
              checked={formFields.interestedInSweepstakesAndChallenges.value}
              onChange={this.handleFormChange}
            />
            <Checkbox
              name="interestedInSocialEmotionalLearning"
              label="Social Emotional Learning (SEL)"
              checked={formFields.interestedInSocialEmotionalLearning.value}
              onChange={this.handleFormChange}
            />
          </Column>
        </Row>
        <h3 className="subscribe__birthday-heading">Birthday</h3>
        <div className="subscribe__birthday-fields">
          <Select
            name="birthdayMonth"
            label="Birthday Month"
            placeholder="Month"
            options={{
              1: "January",
              2: "February",
              3: "March",
              4: "April",
              5: "May",
              6: "June",
              7: "July",
              8: "August",
              9: "September",
              10: "October",
              11: "November",
              12: "December",
            }}
            value={formFields.birthdayMonth.value}
            labelHidden
            onChange={this.handleFormChange}
          />
          <Select
            name="birthdayDay"
            label="Birthday Day"
            placeholder="Day"
            options={[...Array(31).keys()].map(number => 1 + number)}
            value={formFields.birthdayDay.value}
            labelHidden
            onChange={this.handleFormChange}
          />
          <Select
            name="birthdayYear"
            label="Birthday Year"
            placeholder="Year"
            options={[...Array(85).keys()].map(
              number => new Date().getFullYear() - number
            )}
            value={formFields.birthdayYear.value}
            labelHidden
            onChange={this.handleFormChange}
          />
        </div>
        <Checkbox
          name="subscribeToDE"
          label={
            <>
              Stay up-to-date with service enhancements, new content,
              professional development opportunities, special events, education
              program and news from Discovery Education in accordance with the
              following{" "}
              <a
                href="https://www.discoveryeducation.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </>
          }
          checked={formFields.subscribeToDE.value}
          onChange={this.handleFormChange}
        />
        <Checkbox
          name="subscribeToPartner"
          label={
            <>
              I would like to receive information about <em>Amazing Me</em> initiatives in accordance with the following{" "}
              <a
                href="https://www.discoveryeducation.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </>
          }
          checked={formFields.subscribeToPartner.value}
          onChange={this.handleFormChange}
        />
        <Button className="global__button">Submit</Button>
      </Form>
    )
  }

  renderThankYou = () => (
    <p>
      Thank you for your interest in the program. You will be notified of any
      updates and annoucements.
    </p>
  )

  render() {
    const { trigger } = this.props
    const { formComplete, errors } = this.state

    return (
      <Modal trigger={trigger}>
        <div className="subscribe">
          <h2>Sign Up for Updates</h2>
          {errors.length > 0 && (
            <ul className="subscribe__errors" ref={e => (this.errors = e)}>
              {errors.map((error, i) => (
                <li key={i}>{error}</li>
              ))}
            </ul>
          )}
          {formComplete ? this.renderThankYou() : this.renderFields()}
        </div>
      </Modal>
    )
  }
}

Subscribe.propTypes = {
  trigger: PropTypes.node.isRequired,
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <Subscribe data={data} {...props} />}
  />
)
